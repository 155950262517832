@import "~react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper {
    display: block;    
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply tw-text-5xl;
    }
    h2 {
        @apply tw-text-4xl;
    }
    h3 {
        @apply tw-text-3xl;
    }
    h4 {
        @apply tw-text-2xl;
    }
    h5 {
        @apply tw-text-xl;
    }
    h6 {
        @apply tw-text-lg;
    }
}

@layer utilities {
    .tw-progress-pill {
      min-height: 12px;
      min-width: 12px;
      max-height: 20px;
      max-width: 20px;
      display: inline-block;
      border-radius: 50%;
      background-color: #DBDDE0;
    }

    .tw-form-label-focus-within {
        &:focus-within label {
            @apply tw-text-blue
        }
    }

    .tw-lds-dual-ring {
        border-radius: 50%;
        border-color: #207CBA transparent #207CBA transparent;
        animation: lds-dual-ring 1.2s linear infinite;
        @keyframes lds-dual-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .vp-text-link-bold {
        @apply tw-text-sm;
        @apply tw-font-semibold;
    }

    .vp-field-label {
        @apply tw-text-xs;
    }

    .vp-field-helper-text {
        @apply tw-font-medium;
        @apply tw-leading-4;
        font-size: 10px;
    }

    .vp-p-small {
        @apply tw-font-normal;
        @apply tw-text-xs;
        line-height: 1.125rem;
    }

    .vartana-p-small-bold {
        @apply tw-text-xs;
        @apply tw-font-semibold;
    }

    .vp-body {
      @apply tw-text-sm;
    }
}

// .react-custom-select__control {
//     @apply tw-border tw-border-solid tw-rounded-md tw-border-gray-300 tw-shadow-sm tw-placeholder-grey-3 sm:tw-text-sm  #{!important};
    
    // tw-placeholder-grey-3
// }

// .react-custom-select__value-container {
//     @apply tw-py-1 tw-px-4 #{!important};
// }

